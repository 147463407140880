import {
  getSession,
  clearSession,
  createSessionId,
  getLastViewedScreen,
  setSession,
} from "../services/manager";
import { ReactComponent as SpinnerIcon } from "../assets/icons/spinner.svg";
import { startSession } from "../services/api";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BackgroundImage from "../assets/background.webp";
import Button from "../components/Button";
import Dialog from "../components/Dialog";
import ENFlag from "../assets/flags/en.svg";
import DUFlag from "../assets/flags/du.png";
import Logo from "../assets/logo.svg";
import wemindLogo from "../assets/wemind-blue 1.svg";
import NLFlag from "../assets/flags/nl.svg";
import RespondentTextField from "../components/RespondentTextField";
import PincodeNumberField from "../components/PincodeNumberField";
import type { AxiosError } from "axios";
import useValidation from "../hooks/useValidation";

export default function StartSessionScreen() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [respondentNumber, setRespondentNumber] = useState("");
  const [pincode, setPincode] = useState("");
  const [isAborted, setAborted] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const validation = useValidation(pincode, "numeric|size:5");
  const loadingDelay = useRef<number>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const validPincodes = [
    62127, 87503, 65519, 36110, 43337, 82252, 10838, 78976, 65703, 27188, 90470,
    34229, 70104, 27987, 26900, 12304, 79266, 82645, 24640, 68970, 18972, 74197,
    76368, 69977, 86401, 39334, 76992, 71936, 65568, 26250, 62507, 14800, 44190,
    80579, 94915, 96910, 57500, 68828, 94997, 68633, 30000, 89929, 13136, 25146,
    13468, 30289, 76203, 68692, 99230, 52361, 99944, 44892, 74430, 63977, 55118,
    37151, 95758, 20971, 69021, 54585, 53821, 32500, 11737, 54703, 81818, 45009,
    90890, 79110, 61165, 83498, 29361, 10912, 41589, 37128, 82342, 23333, 81582,
    14571, 48277, 41815, 54617, 45855, 50358, 55922, 96795, 10990, 17033, 18998,
    75359, 48226, 10991, 90514, 32647, 18495, 43142, 97677, 56859, 97872, 57631,
    17184, 38950, 63327, 94093, 92228, 89800, 54576, 17039, 59062, 84005, 37556,
    78966, 76382, 74181, 68304, 85013, 13790, 35962, 25948, 27876, 29147, 39902,
    42758, 79786, 61766, 35961, 31854, 78452, 33770, 46460, 18926, 15166, 66029,
    70664, 87449, 77939, 86946, 29365, 96392, 85453, 71749, 15045, 39522, 89559,
    87973, 80082, 49611, 23233, 44921, 90458, 73784, 51636, 64421, 74166, 42393,
    97736, 68889, 14677, 92995, 93271, 17658, 56880,

    11253, 15789, 19384, 21467, 22891, 24573, 28133, 31092, 33456, 35178,
    38744, 40516, 42169, 43855, 45293, 47962, 49738, 51194, 52879, 53147,
    55734, 58291, 60438, 62913, 64852, 66741, 67295, 69584, 71123, 72458,
    75891, 77234, 79547, 81093, 82796, 84157, 85924, 87312, 88759, 89173,
    91284, 92547, 93816, 94278, 95143, 96527, 97184, 98352, 98913, 99578
  ];

  useEffect(() => {
    if (getSession()) {
      setAborted(true);
    }
    console.log("env: " + process.env.REACT_APP_ENVIRONMENT);
    i18n.changeLanguage("");
  }, [i18n]);

  async function handleStartSession() {
    if (respondentNumber == "") {
      setErrorMessage(t("enter_your_name"));
      return;
    }

    setErrorMessage(validation.error);
    if (validation.failed) return;

    if (!validPincodes.find((p) => p == parseInt(pincode))) {
      setErrorMessage(t("pincode_not_valid"));
      return;
    }

    // Delay show of loading icon
    clearTimeout(loadingDelay.current);
    loadingDelay.current = window.setTimeout(() => setLoading(true), 1000);

    try {
      const sessionId = createSessionId();

      // Send request to the api, make sure we can connect.
      if (!process.env.REACT_APP_SKIP_START_SESSION) {
        await startSession(sessionId, respondentNumber, pincode);
      }

      setSession(sessionId);
      navigate("/session/welcome");
    } catch (e) {
      const error = e as AxiosError;
      if (error.isAxiosError) {
        setErrorMessage(error.message);
      }
    } finally {
      setLoading(false);
    }
  }

  async function handleStartNewSession() {
    await clearSession();
    setAborted(false);
  }

  async function handleContinueSession() {
    const viewData = await getLastViewedScreen();
    if (viewData) navigate(viewData.path, { replace: true });
  }

  const loadingIcon = isLoading ? (
    <SpinnerIcon className="animate-spin h-5 absolute left-0" />
  ) : undefined;

  function getVersion() {
    if (
      process.env.REACT_APP_ENVIRONMENT === "DEV" ||
      process.env.REACT_APP_ENVIRONMENT === "QA"
    )
      return (
        <div className="text-center">v{process.env.REACT_APP_VERSION}</div>
      );
  }

  return (
    <div
      className="min-h-screen pt-28 p-12 flex flex-col gap-6 justify-between items-center bg-no-repeat bg-cover bg-center"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      {isAborted && <div className="absolute inset-0 bg-skyblue/50"></div>}

      <div className="relative text-center flex flex-col justify-center items-center">
        <img
          src={Logo}
          alt="Moovd logo"
          className="inline-block mb-4 h-10"
          width="114.75"
          height="30"
        />
        <img src={wemindLogo} alt="WeMind Blue" className="w-52 h-auto" />
      </div>

      <div className="relative flex flex-col flex-1 justify-center gap-6 max-w-xs">
        {isAborted && (
          <>
            <h2 className="text-2xl font-bold text-center">
              {t("your_session_has_been_aborted_but_not_yet_completed")}
            </h2>
            <Button
              label={t("continue_session")}
              variant="quaternary"
              size="large"
              shadow
              onClick={() => handleContinueSession()}
              className="w-full flex-none"
            />
            <button
              className="underline text-center"
              onClick={() => handleStartNewSession()}
            >
              {t("or_start_a_new_session")}
            </button>
          </>
        )}

        {!isAborted && (
          <>
            <div className="relative text-center pin">
              <h1 className="text-xl font-bold">
                {t(
                  "enter_the_name_of_your_therapist_and_the_pincode_for_your_organisation_below"
                )}
              </h1>
            </div>
            <div className="w-73">
              <RespondentTextField
                value={respondentNumber}
                onChange={setRespondentNumber}
              />
            </div>
            <div className="w-73">
              <PincodeNumberField value={pincode} onChange={setPincode} />
            </div>

            {errorMessage && (
              <Dialog
                actions={
                  <Button
                    onClick={() => setErrorMessage(null)}
                    label={t("close")}
                  />
                }
              >
                {t("Error: ")} {errorMessage}
              </Dialog>
            )}

            <Button
              label={t("start")}
              variant="quaternary"
              size="large"
              shadow
              onClick={handleStartSession}
              icon={loadingIcon}
            />
          </>
        )}

        <div className="relative text-center mt-6">
          <p className="text-lg mb-2">{t("choose_language")}</p>
          <img
            onClick={() => i18n.changeLanguage("nl")}
            src={NLFlag}
            alt="Dutch"
            width="32"
            height="32"
            className="h-8 inline-block mx-1 cursor-pointer"
          />
          <img
            onClick={() => i18n.changeLanguage("en")}
            src={ENFlag}
            alt="English"
            width="32"
            height="32"
            className="h-8 inline-block mx-1 cursor-pointer"
          />
          <img
            onClick={() => i18n.changeLanguage("de")}
            src={DUFlag}
            alt="Germany"
            width="32"
            height="32"
            className="h-8 inline-block mx-1 cursor-pointer"
          />
        </div>
        {getVersion()}
      </div>
    </div>
  );
}
